import React from 'react'
import './ProjectCard.css'
import richard from './RichardScaled_3:2.png'
import { Link } from 'react-router-dom';

type PropType = {
    title: string,
    image: string,
    blurb: string,
    link: string
};

class ProjectCard extends React.Component {

    title: string;
    image: string;
    blurb: string;
    link: string;

    constructor(props: PropType) {
        super(props);
        this.title = props.title;
        this.image = props.image;
        this.blurb = props.blurb;
        this.link = props.link;
        if(!this.title) {
            this.title = "NEEDS A TITLE :D";
        }
        if(!this.image) {
            this.image = richard;
        }
        if(!this.blurb) {
            this.blurb = "NEEDS A BLURB";
        }
    }

    render(): React.ReactNode {
        return (
            <div className='ProjectCard-wrapper'>
                <Link to={this.link} className='ProjectCard-link'>
                    <h3 className='ProjectCard-title'>{this.title && this.title}</h3>
                    <hr/>
                    <div className='ProjectCard-content-duo'>
                        <div className='ProjectCard-image-wrapper'>
                            <img className='ProjectCard-image' src={this.image} alt={this.title + " Thumbnail"}></img>
                        </div>
                        <div className='ProjectCard-blurb-wrapper'>
                            <p className='ProjectCard-blurb'>
                                {this.blurb}
                            </p>
                        </div>
                    </div>
                </Link>
            </div>
        );
    }
}
export default ProjectCard