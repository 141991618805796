import React from "react";
import './About.css';
import gabriel_bacon_picture from "./GabrielBaconPicture.jpg";

type PropType = {};

class About extends React.Component {

    about_text: React.ReactNode;

    constructor(props: PropType) {
        super(props);
        this.about_text =
        `Hello, I'm Gabriel Bacon, a Game/Software Developer living in Southern California.
        I'm an alumni of UCSC, graduating in 2024 with a B.S in Computer Science: Computer Game Design.
        While I welcome any programming challenge, I'm particularly interested in systemic games and procedural generation.
        I hope to one day teach computer game design, though I'll fit right into any environment that gives me the opportunity to learn and be challenged everyday!`;
    }

    render(): React.ReactNode {
        return (
            // <div className="About-under-construction-wrapper">
            //     <p className="About-under-construction-text">
            //         This page is currently under construction!<br/>
            //         I appreciate your patience
            //     </p>
            // </div>
            <div className="About-wrapper">
                <div className="About-gabriel-image-wrapper">
                    <img src={gabriel_bacon_picture} alt="Gabriel Bacon" className="About-gabriel-image"></img>
                </div>
                <div className="About-text-wrapper">
                    <p className="About-text">
                        {this.about_text}
                    </p>
                </div>
            </div>
        );
    }
}
export default About;