import React from "react";
import './PermanenceBreakPage.css'

class PermanenceBreakPage extends React.Component {
    render(): React.ReactNode {
        return (
            <div>HEY</div>
        );
    }
}
export default PermanenceBreakPage