import { useLocation } from "react-router-dom";

type PropType = {};

function withPathName(Component: React.ElementType) {
    return function WrappedComponent(props: PropType) {
        let { pathname } = useLocation();
        return <Component {...props} pathName={pathname}/>
    }
}

export default withPathName;